// components/model.jsx
import React, { useState, useEffect, useRef } from 'react';
import CountryFlag from 'react-country-flag';
import axios from 'axios';
import { useGeolocated } from 'react-geolocated';
import './model.css';
import OFlogo from '../images/of-logo.png';

// Start of editable section
// --------------------------------------------------------------------------------
// --------------------------------------------------------------------------------

import BGImage from '../images/texture.png';
import pfpImage from '../images/katepfpuncropped.jpeg';

const ModelInfo = {
    modelName: 'Katie',
    responseTime: '5 minutes',
    homeCity: 'Blackpool',
    homeCountry: 'UK', // Make sure to use the correct country code for the flag
    targetUrl: 'https://tinyurl.com/mariahvip',
    percentOff: '35% OFF',
    totalSalesSlots: '4', // the average number of sales slots to start with
    CurrentActivity: 'Looking for someone to film content with' // What the model is apparently doing in the locale defined by the IP
};
// --------------------------------------------------------------------------------
// --------------------------------------------------------------------------------
// End of editable section


const Model = () => {
    const { coords } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false
        },
        userDecisionTimeout: 5000,
    });
    const [city, setCity] = useState(ModelInfo.homeCity);
    const [countryCode, setCountryCode] = useState(ModelInfo.homeCountry);
    const [countdown, setCountdown] = useState('calculating...');

    useEffect(() => {
        const fetchGeoLocation = async () => {
          try {
            let params = {};
            if (coords) {
              params = {
                latitude: coords.latitude,
                longitude: coords.longitude
              };
            }
            const response = await axios.get('/.netlify/functions/geolocation', { params });
            const localityInfo = response.data.results.find(result => 
                result.address_components.some(comp => comp.types.includes("locality"))
            );
            const countryInfo = response.data.results.find(result => 
                result.address_components.some(comp => comp.types.includes("country"))
            );
            setCity(localityInfo ? localityInfo.address_components.find(comp => comp.types.includes("locality")).long_name : ModelInfo.homeCity);
            setCountryCode(countryInfo ? countryInfo.address_components.find(comp => comp.types.includes("country")).short_name : ModelInfo.homeCountry);
          } catch (error) {
            console.error("Error fetching the geolocation data: ", error);
            setCity(ModelInfo.homeCity); // Fallback value
            setCountryCode(ModelInfo.homeCountry); // Fallback country
          }
        };
      
        fetchGeoLocation();
      }, [coords]);
      
    

    useEffect(() => {
        const interval = setInterval(() => {
            axios.get('/.netlify/functions/countdown')
            .then(response => {
                setCountdown(response.data.countdown);
            })
            .catch(error => {
                console.error("Error fetching the countdown data: ", error);
                setCountdown('less than an hour'); // Fallback value
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const tomorrowDate = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000)); // Add one day
    
        // Function to get the day with ordinal suffix
        const getDayWithOrdinal = (date) => {
            const day = date.getDate();
            let suffix = "th";
            if (day < 11 || day > 13) {
                switch (day % 10) {
                    case 1:  suffix = "st"; break;
                    case 2:  suffix = "nd"; break;
                    case 3:  suffix = "rd"; break;
                }
            }
            return `${day}${suffix}`;
        };
    
        // Format the day and month
        const dayWithOrdinal = getDayWithOrdinal(tomorrow);
        const dayName = tomorrow.toLocaleDateString('en-US', { weekday: 'short' }); // e.g., "Fri"
    
        return `${dayName} ${dayWithOrdinal}`;
    };
    

    // State for the subscriptions countdown
    const [subsLeft, setSubsLeft] = useState(Math.floor(Math.random() * (7 - 4 + 1)) + 4);
    
    // Use a ref to track if the countdown has already been decremented
    const hasDecremented = useRef(false);

    // Effect for decreasing subscriptions left
    useEffect(() => {
        if (hasDecremented.current) {
            return; // If we've already decremented, do nothing
        }
        
        const timeout = setTimeout(() => {
            if (subsLeft > 0) {
                setSubsLeft(subsLeft - 1);
            }
            hasDecremented.current = true; // Mark as decremented
        }, 5000);

        // Clear the timeout if the component unmounts
        return () => clearTimeout(timeout);
    }, [subsLeft]); // Dependency on subsLeft is still needed to get the latest value



    return (
    <div className="flex items-center justify-center min-h-screen" style={{ backgroundImage: `url(${BGImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <ul className="grid place-items-center">
                <li className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow overflow-hidden">
                    <div className="flex flex-1 flex-col p-8">
                        <span className="relative inline-block mx-auto">
                            <img
                                className="h-32 w-32 flex-shrink-0 rounded-full"
                                src={pfpImage}
                                alt={ModelInfo.modelName}
                            />
                            <span className="absolute bottom-0 right-0 block h-4 w-4 rounded-full bg-green-400 ring-2 ring-white" />
                        </span>
                        <h3 className="mt-6 text-lg font-medium text-gray-900">{ModelInfo.modelName}</h3>
                        <dl className="mt-1 flex-grow">
                            <dt className="sr-only">Location</dt>
                            <dd className="text-sm text-gray-500">
                                {ModelInfo.CurrentActivity} <br /> Staying in {city} {countryCode && <CountryFlag countryCode={countryCode} svg />}  until {tomorrowDate()}
                            </dd>
                            <dd className="mt-3">
                                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    Online Now
                                </span>
                            </dd>
                            <dd className="mt-3">
                                <span className="text-xs text-gray-500">Avg response time: {ModelInfo.responseTime}</span>
                            </dd>
                        </dl>
                    </div>
                    <div className="px-4 py-4 bg-gray-100">
                        <p className="message text-sm text-gray-600">Send me a message here, I reply to all my DMs ❤️</p>
                        <a
                            href={ModelInfo.targetUrl}
                            className="mt-3 inline-flex items-center justify-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                            style={{ backgroundColor: '#00aeef' }}
                        >
                            <img src={OFlogo} className="h-7 w-7" alt="Logo" />
                            Send me a message
                        </a>
                        <div className="sale-offer mt-3 text-xs font-bold text-gray-500">
            {ModelInfo.percentOff} sale ends in {countdown} - Only {subsLeft} slots remaining </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Model;